<div class="back">
    <div class="container">
        <img src="./assets/images/eleta.jpg" alt="logo" />
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="login()" novalidate>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="text" formControlName="email" class="form-control">
                <app-validator [control]="form.controls['email']"></app-validator>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control">
                <app-validator [control]="form.controls['password']"></app-validator>
            </div>
            <div class="form-group">
                <button class="btn-text" type="submit" [disabled]="!form.valid">
                    <div class="text">Login</div>
                </button>
                <!--<a (click)="showDialog()" i18n="@@forgot-password">Forgot password</a>-->
            </div>
        </form>
    </div>
</div>
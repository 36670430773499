
		<div class="container1">
			<div class="title">
				<h2>Reportes</h2>
			</div>

			<div class="button1">
				<div class="buttons">
					<button class="b1 fa fa-twitter fa-5x" title="Cerrar Facturas" (click)="openModal(template)">
						<i class="material-icons">lock</i>
					</button>
					<button (click)="exportTotalAsXLSX()"  title="Reporte Total">
						<i  class="material-icons">receipt</i>
					</button>
					<button (click)="exportDetailAsXLSX()" title="Reporte Detallado">
						<i class="material-icons">description</i>
					</button>
					<button (click)="exportPagosAsXLSX()" title="Pago de proveedores">
						<i class="material-icons">account_balance_wallet</i>
					</button>
				</div>
			</div>
			<!--<div class=""></div>
			StartDate-->
			<div class="date1">
				<my-date-picker [options]="myDatePickerOptions"
					[(ngModel)]="filterService.filter['startDate']"
					(change)="filterService.put('startDate',
					$event.target.value)">
				</my-date-picker>
			</div>

			<!--EndDate-->
			<div class="date2">
				<my-date-picker [options]="myDatePickerOptions"
					[(ngModel)]="filterService.filter['endDate']"
					(change)="filterService.put('endDate',
					$event.target.value)">
				</my-date-picker>
			</div>

			<div class="item5">
				<input matInput placeholder="Nombre o Identificación" [(ngModel)]="filterService.filter['nitName']"
									(change)="filterService.put('nitName',
									$event.target.value)">
			</div>

			<div class="item6">
				<mat-select placeholder="Tipo de Proveedor" [(ngModel)]="filterService.filter['typeProvider']"
							(change)="filterService.put('typeProvider',
							$event.target.value)">
					<mat-option>Ninguna</mat-option>
					<mat-option *ngFor="let pt of provType" [value]="pt.id"> {{pt.nameProviderType }} </mat-option>
				</mat-select>
			</div>

			<div class="item7">
				<mat-select placeholder="Estatus" [(ngModel)]="filterService.filter['statusInvoice']"
							(change)="filterService.put('statusInvoice',
							$event.target.value)">
					<mat-option>Ninguna</mat-option>
					<mat-option *ngFor="let s of status" [value]="s.id"> {{s.name}} </mat-option>
				</mat-select>
			</div>

			<div class="item8">
				<button (click)="list(0)">
					<i class="material-icons">search</i>
				</button>
			</div>
		</div>

		<div class="tool-bar both-side">
			<div class="right row">
				<button class="btn-icon"  title="Crear Factura" type="button" (click)="create()">
					<i class="material-icons">add</i>
				</button>
				<!-- <button class="btn-icon" type="button">
				<button class="btn-icon" title="Delete" type="button"
				(click)="confirmDelete = false" *ngIf="tableService.getSelectedsLength() > 0">
					<i class="material-icons">delete</i>
				</button> -->
			</div>
		</div>

		<!--Table -->
		<div class="mat-elevation-z8" >
			<!-- Definition table -->
			<table class="table" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

				<!-- Checkbox Column -->
				<ng-container matColumnDef="select">
				  <th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="$event ? masterToggle() : null"
								  [checked]="selection.hasValue() && isAllSelected()"
								  [indeterminate]="selection.hasValue() && !isAllSelected()">
					</mat-checkbox>
				  </th>
				  <td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
								  (change)="$event ? selection.toggle(row) : null"
								  [checked]="selection.isSelected(row)">
					</mat-checkbox>
				  </td>
				</ng-container>

				<!-- Position Provider -->
				<ng-container matColumnDef="provider.nameProvider">
					<div class="sort sort-up"></div>
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Nombre del Proveedor</th>
					<div class="sort sort-down"></div>
					<td mat-cell *matCellDef="let invoice"> {{invoice.provider?.nameProvider || '-'}} </td>
				</ng-container>

				<!-- Position statusInvoice -->
				<ng-container matColumnDef="statusInvoice.name">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let invoice"> {{invoice.statusInvoice?.name || '-'}} </td>
				</ng-container>

				<!-- Position ProviderType -->
				<ng-container matColumnDef="provider.providerType.nameProviderType">
					<th class="table-header" mat-header-cell *matHeaderCellDef  mat-sort-header><span>Tipo de Proveedor</span></th>
					<td mat-cell *matCellDef="let invoice"> {{invoice.provider.providerType?.nameProviderType || '-'}} </td>
				</ng-container>

				<!-- Position  openDateInvoice -->
				<ng-container matColumnDef="startDate">
					<th class="table-header" mat-header-cell *matHeaderCellDef  mat-sort-header>Fecha de Apertura</th>
						<td mat-cell *matCellDef="let invoice"> {{invoice.startDate | date:'yyyy-MM-dd' || '-'}} </td>
				</ng-container>

				<!-- Position totalInvoice -->
				<ng-container matColumnDef="totalInvoice">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Total Invoice</th>
					<td mat-cell *matCellDef="let invoice"> {{invoice.totalInvoice || '-' }}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
	  			<tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="element-row"  (click)="read(row.id)"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
		</div>

		<ng-template #template>
			<div class="modal-body text-center">
				<div class="dialog-title">Confirmación </div>
				<div class="dialog-message">¿Estas seguro que quieres cerrar todas las facturas?</div>
				<div class="dialog-options">
					<button class="btn-text green" type="button" (click)="close()">
						<div class="text">Si</div>
					</button>
					<button class="btn-text red" type="button" (click)="decline()" >
						<div class="text">No</div>
					</button>
				</div>
			</div>
		</ng-template>


	
<div class="grid-h">
    <div class="nav">
        <app-sidebar></app-sidebar>
    </div>
    <div class="content grid-v">
        <section class="header">
            <header>
                <app-topbar></app-topbar>
                <app-breadcrumbs id="breadcrumbs"></app-breadcrumbs>
            </header>
        </section>

        <section class="content">
            <router-outlet></router-outlet>
        </section>

        <!--<footer class="footer"></footer>-->
    </div>
</div>
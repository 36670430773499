
		<div class="mat-elevation-z8" >
			<!-- Definition table -->
			<table  mat-table [dataSource]="invoices" class="table mat-elevation-z8">

				<!-- Checkbox Colum
				<ng-container matColumnDef="select">
				  <th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="$event ? masterToggle() : null"
								  [checked]="selection.hasValue() && isAllSelected()"
								  [indeterminate]="selection.hasValue() && !isAllSelected()">
					</mat-checkbox>
				  </th>
				  <td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
								  (change)="$event ? selection.toggle(row) : null"
								  [checked]="selection.isSelected(row)">
					</mat-checkbox>
				  </td>
				</ng-container> -->

				<!-- Position nameItemType -->
				<ng-container matColumnDef="itemType.nameItemType">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Iten</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.itemType?.nameItemType || '-'}}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position lot.nameLot -->
				<ng-container matColumnDef="lot.nameLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Nombre del Lote</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.lot?.nameLot || '-'}} </td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position FechaInvoiceDetail-->
				<ng-container matColumnDef="startDate">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Fecha de Apertura</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{ invoiceDetail.startDate || '-'}}</td>
					<td mat-footer-cell *matFooterCellDef><strong>Total </strong></td>
				</ng-container>

				<!-- Position store.nameStore -->
				<ng-container matColumnDef="store.nameStore">
					<th class="table-header" mat-header-cell *matHeaderCellDef><span>Nombre de la Tienda</span></th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.store?.nameStore|| '-'}} </td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position  priceItemTypeByLot -->
				<ng-container matColumnDef="priceItemTypeByLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Precio</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.priceItemTypeByLot|| '-'}} </td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position costItemType -->
				<ng-container matColumnDef="costItemType">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Costo</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.costItemType|| '-'}}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position amountInvoiceDetail -->
				<ng-container matColumnDef="amountInvoiceDetail">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Cantidad</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.amountInvoiceDetail|| '-'}}</td>
					<td mat-footer-cell *matFooterCellDef> {{ getTotalCantidad()|| '-'}} </td>
				</ng-container>

				<!-- Position nameReceived -->
				<ng-container matColumnDef="nameReceived">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Recibido</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.nameReceived|| '-'}}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position nameReceived-->
				<ng-container matColumnDef="nameDelivered">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Entregado</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.nameDelivered|| '-'}}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<!-- Position nameDelivered-->
				<ng-container matColumnDef="totalInvoiceDetail">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.totalInvoiceDetail|| '-' }}</td>
					<td mat-footer-cell *matFooterCellDef> {{ total || '-' }}</td>
				</ng-container>

				<!-- Position statusInvoiceDetail
				<ng-container matColumnDef="statusInvoiceDetail">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Name Delivered</th>
					<td mat-cell *matCellDef="let invoiceDetail"> {{invoiceDetail.statusInvoiceDetail || '-'}}</td>
				</ng-container>-->

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="element-row"  (click)="read(row.id)"></tr>
				<tr mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></tr>

			</table>
			<!--<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>-->
		</div>

	
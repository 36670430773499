import { PurityService } from './purity.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [],
	exports: [
	],
	providers: [
		PurityService
	]
})
export class PurityModule { }


	<div class= "container">
		<form *ngIf="form" [formGroup]="form"  (ngSubmit)="create()">
		<legend><span>Datos de la Factura</span></legend>
			<div class="wrap-fields">
				<div class="field form-field">
					<mat-form-field class="full-width">
						<mat-select required [formControl]="form.controls['provider']">
							<mat-option *ngFor="let c of cosecheros" [value]="{id: c.id}">{{c.nameProvider}}</mat-option>
						</mat-select>
						<mat-label><b>Cosechador</b></mat-label>
					</mat-form-field>
					<app-validator [control]="form.controls['provider']"></app-validator>
				</div>
				<button class="buttonStyle" (click)="addItemType()" title="Añadir Detalle a la Cosecha">
					<i class="material-icons">add_shopping_cart</i>
				</button>
			</div>
			<div formArrayName="itemtypes">
				<div style="margin-top:5px; margin-bottom:5px;" *ngFor="let item of form.get('itemtypes').controls;
					let i=index">
					<fieldset>
						<legend><h4>Detalle{{i+1}}: </h4></legend>
						<div [formGroupName]="i">
							<div class="wrap-fields">
								<div class="field">
									<mat-form-field>
										<mat-select required>
											<mat-option *ngFor="let f of farms" [value]="{id: f.id}">{{f.nameFarm}}</mat-option>
										</mat-select>
										<mat-label><b>Granja</b></mat-label>
									</mat-form-field>
								</div>
								<div class="field">
									<mat-form-field>
										<mat-select required [formControl]="item.controls['lot']">
											<mat-option *ngFor="let l of lots" [value]="{id: l.id}">{{l.nameLot}}</mat-option>
										</mat-select>
										<mat-label><b>Lote</b></mat-label>
									</mat-form-field>
									<app-validator [control]="item.controls['lot']"></app-validator>
								</div>
								<div class="field">
									<mat-form-field>
										<mat-select required [formControl]="item.controls['itemType']">
											<mat-option *ngFor="let it of itemType" [value]="{id: it.id}">{{it.nameItemType}}</mat-option>
										</mat-select>
										<mat-label><b>Grano</b></mat-label>
									</mat-form-field>
									<app-validator [control]="item.controls['itemType']"></app-validator>
								</div>
								<button class="buttonStyle2" (click)="deleteItemType(i)" title="Eliminar Detalle a la Cosecha">
									<i class="material-icons">delete_sweep</i>
								</button>
							</div>
							<div class="wrap-fields">
								<div class="field">
									<mat-form-field class="full-width2">
										<input matInput required formControlName="amountInvoiceDetail" placeholder="Cantidad"
										class="example-right-align">
									</mat-form-field>
									<app-validator [control]="item.controls['amountInvoiceDetail']"></app-validator>
								</div>
								<div class="field">
									<mat-form-field class="full-width">
										<input matInput formControlName="noteInvoiceDetail" placeholder="Observaciones">
									</mat-form-field>
									<app-validator [control]="item.controls['noteInvoiceDetail']"></app-validator>
								</div>
							</div>
						</div>
					</fieldset>
				</div>
			</div>

			<div class="options row">
				<button mat-raised-button class="btn-text" type="submit" [disabled]="form?.invalid" >Guardar</button>
			</div>
		</form>
	</div>
	

		<h3 class="title">Detalles del Reporte</h3>
		<div class="tool-bar both-side">
			<!---->
			<div class="right row">
				<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" title="Eliminar Factura" type="button" (click)="openModal(template)">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="answer">
			<div class="fieldset">
				<div class="legend">Datos de la Factura</div>

				<div class="wrap-fields">
					<div>
						<span class="label">Nombre del Proveedor</span>
						<span class="output">{{ invoice.provider?.nameProvider || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Tipo de Proveedor</span>
						<span class="output">{{ invoice.provider?.providerType?.nameProviderType || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Status de la Factura</span>
						<span class="output">{{ invoice.statusInvoice?.name || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Fecha de Creación</span>
						<span class="output">{{ invoice.startDate || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Total de la Factura</span>
						<span class="output">{{ invoice.totalInvoice || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="legend">Detalles de la Factura</div>

			<!--
			<div class="tool-bar both-side">
				<div class="right row">
					<button class="btn-icon"  title="Crear Factura" type="button" (click)="create()">
						<i class="material-icons">add</i>
					</button>
				</div>
			</div> -->
			<app-invoice-detail-list></app-invoice-detail-list>
		</div>

		<ng-template #template>
			<div class="modal-body text-center">
				<div class="dialog-title">Confirmación </div>
				<div class="dialog-message">¿Estas seguro que quieres eliminar esta factura?</div>
				<div class="dialog-options">
					<button class="btn-text green" type="button" (click)="delete()">
						<div class="text">Si</div>
					</button>
					<button class="btn-text red" type="button" (click)="decline()" >
						<div class="text">No</div>
					</button>
				</div>
			</div>
		</ng-template>
	

	<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
		<fieldset>
			<legend><span>Datos del Cosechador</span></legend>

			<div class="wrap-fields">
				<div class="field form-field">
					<mat-form-field class="example-full-width">
						<mat-select required [formControl]="form.controls['statusProvider']">
							<mat-option *ngFor="let s of status" [value]="s.id">{{s.name}}
							</mat-option>
						</mat-select>
						<mat-label><b>Estatus</b></mat-label>
					</mat-form-field>
					<app-validator [control]="form.controls['statusProvider']"></app-validator>
				</div>
			</div>

			<!--<div class="wrap-fields">
				<div class="field form-field">
					<mat-form-field class="example-full-width">
						<mat-select required [formControl]="form.controls['deleted']">
							<mat-option [value]="true">Inactivo</mat-option>
							<mat-option [value]="false">Activo</mat-option>
						</mat-select>
						<mat-label><b>Status</b></mat-label>
					</mat-form-field>
				</div>
			</div> -->
			<div class="wrap-fields">
				<div class="field">
					<mat-form-field  required class="example-full-width">
						<input matInput formControlName="nameProvider" placeholder="Nombre del Cosechador">
					</mat-form-field>
					<app-validator [control]="form.controls['nameProvider']"></app-validator>
				</div>
			</div>
			<div class="wrap-fields">
				<div class="field">
					<mat-form-field  required class="example-full-width">
						<input matInput formControlName="nitProvider" placeholder="DNI">
					</mat-form-field>
					<app-validator [control]="form.controls['nitProvider']"></app-validator>
				</div>
			</div>
		</fieldset>

		<fieldset>
			<legend><span>Datos de Contacto</span></legend>
			<div class="wrap-fields">
				<div class="field">
					<mat-form-field class="example-full-width">
						<input matInput formControlName="numberProvider" placeholder="Numero Telefónico">
						<app-validator [control]="form.controls['numberProvider']"></app-validator>
					</mat-form-field>
				</div>
				<div class="field">
					<mat-form-field class="example-full-width">
						<input matInput formControlName="emailProvider" placeholder="Correo Electrónico">
					</mat-form-field>
					<app-validator [control]="form.controls['emailProvider']"></app-validator>
				</div>
			</div>
			<div class="wrap-fields">
				<div class="field">
					<mat-form-field  required class="example-full-width">
						<input matInput formControlName="addressProvider" placeholder="Dirección">
					</mat-form-field>
					<app-validator  [control]="form.controls['addressProvider']"></app-validator>
				</div>
			</div>
		</fieldset>

		<div class="options row">
			<button mat-raised-button class="btn-text">Guardar</button>
		</div>
	</form>


		<h2 class="title">Lotes</h2>

		<div class="row">
			<div class="field filter">
				<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar">
			</div>

			<div class="field">
			<!-- <mat-select placeholder="Estatus" [(ngModel)]="selected">-->
				<mat-select placeholder="Granja" [(ngModel)]="filterService.filter['farm']"
															(change)="filterService.put('farm',
															$event.target.value)">
					<mat-option>Ninguna</mat-option>
					<mat-option *ngFor="let f of farm" [value]="f.id"> {{f.nameFarm}}</mat-option>
				</mat-select>
			</div>

			<div class="field">
				<mat-select placeholder="Estatus" [(ngModel)]="filterService.filter['statusLot']"
													(change)="filterService.put('statusLot',
													$event.target.value)">
					<mat-option>Ninguna</mat-option>
					<mat-option *ngFor="let s of status" [value]="s.id"> {{s.name}}</mat-option>
				</mat-select>
			</div>

			<div class="container-button-filter">
				<button class="btn-icon" title="Search" type="button" (click)="list(0)">
					<i class="material-icons">search</i>
				</button>
			</div>
		</div>


		<div class="tool-bar both-side">
			<div class="right row">
				<button class="btn-icon" type="button" (click)="create()">
					<i class="material-icons">add</i>
				</button>
				<!-- <button class="btn-icon" type="button">
				<button class="btn-icon" title="Delete" type="button"
				(click)="confirmDelete = false" *ngIf="tableService.getSelectedsLength() > 0">
					<i class="material-icons">delete</i>
				</button> -->
			</div>
		</div>


		<div class="mat-elevation-z8" >
			<!-- Definition table -->
			<table class="table" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

				<!-- Checkbox Column -->
				<ng-container matColumnDef="select">
				  <th mat-header-cell *matHeaderCellDef>
					<mat-checkbox (change)="$event ? masterToggle() : null"
								  [checked]="selection.hasValue() && isAllSelected()"
								  [indeterminate]="selection.hasValue() && !isAllSelected()">
					</mat-checkbox>
				  </th>
				  <td mat-cell *matCellDef="let row">
					<mat-checkbox (click)="$event.stopPropagation()"
								  (change)="$event ? selection.toggle(row) : null"
								  [checked]="selection.isSelected(row)">
					</mat-checkbox>
				  </td>
				</ng-container>

				<!-- Position Namme -->
				<ng-container matColumnDef="nameLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef><span (click)="test()">Nombre del Lote</span></th>
					<td mat-cell *matCellDef="let lot"> {{lot.nameLot|| '-'}} </td>
				</ng-container>

				<!-- Position Farm -->
				<ng-container matColumnDef="farm.nameFarm">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Granja</th>
					<td mat-cell *matCellDef="let lot"> {{lot.farm.nameFarm|| '-'}} </td>
				</ng-container>

				<!-- Position  Status-->
				<!-- <ng-container matColumnDef="deleted">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
					<td mat-cell *matCellDef="let lot">
						<div *ngIf="lot.deleted" >Inactivo</div>
						<div *ngIf="!lot.deleted">Activo</div>
					</td>
				</ng-container>-->

				<!-- Position  Status-->
				<ng-container matColumnDef="lot.statusLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Estatus</th>
					<td mat-cell *matCellDef="let lot"> {{lot.statusLot?.name|| '-'}} </td>
				</ng-container>

				<!-- Position  Status-->
				<ng-container matColumnDef="areaLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Área</th>
					<td mat-cell *matCellDef="let lot"> {{lot.areaLot|| '-'}} </td>
				</ng-container>

				<!-- Position  Status-->
				<ng-container matColumnDef="heighLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Altura</th>
					<td mat-cell *matCellDef="let lot"> {{lot.heighLot|| '-'}} </td>
				</ng-container>

				<!-- Position  Status-->
				<ng-container matColumnDef="priceLot">
					<th class="table-header" mat-header-cell *matHeaderCellDef mat-sort-header>Precio</th>
					<td mat-cell *matCellDef="let lot"> {{lot.priceLot|| '-'}} </td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
	  			<tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="element-row"  (click)="read(row.id)"></tr>
			</table>
			<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
		</div>

	
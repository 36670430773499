
		<h3 class="title">Detalles del Lote</h3>
		<div class="tool-bar both-side">
			<div class="right row">
				<button class="btn-icon" title="Actualizar" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" title="Eliminar" type="button" (click)="openModal(template)">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="answer">
			<div class="fieldset">
				<div class="legend">Datos del Lote</div>
				<!--<div class="wrap-fields">
					<div>
						<span class="label">Status</span>
						<span class="output" *ngIf="lot.deleted" >Inactivo</span>
						<span class="output" *ngIf="!lot.deleted">Activo</span>
					</div>
				</div>-->
				<div class="wrap-fields">
					<div>
						<span class="label">Estatus</span>
						<span class="output">{{lot.statusLot?.name|| '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Nombre</span>
						<span class="output">{{lot.nameLot|| '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Granja</span>
						<span class="output">{{lot.farm?.nameFarm || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Área</span>
						<span class="output">{{lot.areaLot || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Altura</span>
						<span class="output">{{lot.heighLot || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Precio</span>
						<span class="output">{{lot.priceLot || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<ng-template #template>
			<div class="modal-body text-center">
				<div class="dialog-title">Confirmación </div>
				<div class="dialog-message">¿Estas seguro que quieres eliminar este lote?</div>
				<div class="dialog-options">
					<button class="btn-text green" type="button" (click)="delete()">
						<div class="text">Si</div>
					</button>
					<button class="btn-text red" type="button" (click)="decline()" >
						<div class="text">No</div>
					</button>
				</div>
			</div>
		</ng-template>
	
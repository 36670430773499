
		<h3 class="title">Detalle del Item</h3>
		<!---->
		<div class="tool-bar both-side">
			<div class="right row">
				<button class="btn-icon" title="Actualizar item" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>
				<!--
				<button class="btn-icon" title="Eliminar Item de la Factura" type="button" (click)="openModal(template)">
					<i class="material-icons">delete</i>
				</button>-->
			</div>
		</div>

		<div class="answer">
			<div class="fieldset">
				<div class="wrap-fields">
					<div>
						<span class="label">Fecha de Apertura</span>
						<span class="output">{{ invoiceDetail.startDate || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
						<span class="label">Tipo de Item</span>
						<span class="output">{{ invoiceDetail.itemType?.nameItemType || '-'}}</span>
				</div>
				<div *ngIf="option == 1">
				<div class="wrap-fields">
					<div>
						<span class="label">Recibido</span>
						<span class="output">{{ invoiceDetail.nameReceived || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label">Entregado</span>
						<span class="output">{{invoiceDetail.nameDelivered || '-'}}</span>
					</div>
				</div>
				<div class="wrap-fields">
					<div>
						<span class="label" >Nombre de la Tienda</span>
						<span class="output">{{ invoiceDetail.store?.nameStore || '-'}}</span>
					</div>
				</div>

				<div class="wrap-fields">
					<div>
						<span class="label">Costo</span>
						<span class="output">{{ invoiceDetail.costItemType || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="wrap-fields" *ngIf="option == 2">
				<div>
					<span class="label">Nombre del Lote</span>
					<span class="output">{{ invoiceDetail.lot?.nameLot || '-'}}</span>
				</div>
			</div>
			<div class="wrap-fields">
				<div>
					<span class="label">Cantidad</span>
					<span class="output">{{ invoiceDetail.amountInvoiceDetail || '-'}}</span>
				</div>
			</div>
			<div class="wrap-fields">
				<div>
					<span class="label">Total del Item</span>
					<span class="output">{{ invoiceDetail.totalInvoiceDetail || '-'}}</span>
				</div>
			</div>
			<div class="wrap-fields">
				<div>
					<span class="label">Observaciónes</span>
					<span class="output">{{ invoiceDetail.note || '-'}}</span>
				</div>
			</div>
        </div>
    </div>

	<ng-template #template>
		<div class="modal-body text-center">
			<div class="dialog-title">Confirmación </div>
			<div class="dialog-message">¿Estas seguro que quieres eliminar esta factura?</div>
			<div class="dialog-options">
				<button class="btn-text green" type="button" (click)="delete()">
					<div class="text">Si</div>
				</button>
				<button class="btn-text red" type="button" (click)="decline()" >
					<div class="text">No</div>
				</button>
			</div>
		</div>
	</ng-template>
	<!---->
	

	<h2 class="title"><span>Crear Factura</span></h2>
			<!-- -->

			<mat-radio-group [(ngModel)]="action">
				<mat-radio-button class="example-radio-button" class="example-radio-button" *ngFor="let season of operacion" [value]="season.name">
					{{season.name}}
				</mat-radio-button>
			</mat-radio-group>

			<app-harvest-create *ngIf= "action == 'Cosecha'"></app-harvest-create>
			<app-purchase-create *ngIf= "action == 'Compra'"></app-purchase-create>
	
<div class="back">
    <div class="container">
        <img src="./assets/images/eleta.jpg" alt="logo" />
        <div>{{title}}</div>
        <form [formGroup]="resetForm" (ngSubmit)="reset($event)" #f="ngForm" novalidate>
            <div class="form-group">
                <label for="oldPassword">Contraseña Actual</label>
                <input class="form-control" formControlName="oldPassword" type="password">
                <div *ngIf="f.submitted && !resetForm.controls['oldPassword'].valid" class="help-block">Antigua contraseña es requerida</div>
            </div>
            <div class="form-group">
                <label for="newPassword">Nueva Contraseña</label>
                <input class="form-control" formControlName="newPassword" type="password">
                <div *ngIf="f.submitted && !resetForm.controls['newPassword'].valid" class="help-block">Nueva contraseña es requerida</div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirmar Nueva Contraseña</label>
                <input class="form-control" formControlName="confirmPassword" type="password">
                <div *ngIf="f.submitted && !resetForm.controls['confirmPassword'].valid" class="help-block">Confirmar contraseña es requerido</div>
            </div>
            <div class="form-group">
                <button class="btn-text" type="submit">
                    <div class="text">Aceptar</div>
                </button>
            </div>
        </form>
    </div>
</div>